.admin-user-container {
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 2rem 1rem 2rem;
    color: #4c4d4f;
    border-bottom: 1px solid #d9d8d8;
  }
  .main-detail-container {
    padding: 2rem 2rem 1rem 2rem;

    .pointer-cursor {
      cursor: pointer;
    }
    .red-row {
      background-color: #fdb6bd;
    }


    .role-cell {
      text-transform: capitalize;
    }
    .edit-btn {
      font-weight: 500;
      font-size: 14px;
      color: #0469ff;
      background-color: #ffffff;
    }
    .destroy-btn {
      font-weight: 500;
      font-size: 14px;
      color: #dc3545;
      border-color: #dc3545;
      background-color: #ffffff;
    }
    .activate-btn {
      font-weight: 500;
      font-size: 14px;
      color: #108a00;
      border-color: #108a00;
      background-color: #ffffff;
    }
    .table-cell {
      vertical-align: middle;
      text-align: center;
    }
  }
  .pagination {
    justify-content: end;
  }
}
