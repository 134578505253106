.quick-match-area {
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;

  .login-container {
    background-color: #f5f5f5;
    border-radius: 20px;
    border: 9px solid #dbebdd;
    padding: 26px;
    display: flex;
    flex-direction: column;
    justify-self: flex-start;

    .title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 35px;
      align-self: center;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      padding: 5px;
      color: #71757b;
      margin-top: 10px;
      border-bottom: 2px solid #71757b;
    }

    .form-container {
      margin-top: 30px;
    }

    .Pass-login {
      cursor: pointer;
    }

    .button-parent {
      background: linear-gradient(180deg, #0469ff 0%, #5fafe0 0.01%, #479fd6 41.67%, #226a99 100%);
      padding: 2px;
      border-radius: max(min(2vw, 7px), 7px);
      display: flex;
      justify-content: center;
    }

    .button-container {
      width: auto;
      border-radius: max(min(2vw, 7px), 7px);
      margin: 0;
      order: 0;
      align-self: stretch;
      flex-grow: 1;
      font-family: 'Bookman Old Style';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.55);
      border: none;
      background: linear-gradient(90deg, #0d6efd 0%, #5fafe0 0.01%, #479fd6 41.67%, #479fd6 100%);
    }

    .form-control:focus {
      box-shadow: none;
    }
    .input-group-text {
      background-color: #e7e5df;
      border: none;
      color: #71757b;
      border-radius: 4.2px;
      height: 45px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
    .input-group {
      border: 1px solid rgba(188, 188, 188, 1);
      border-radius: 0.375rem;
    }
    .input-group.is-invalid {
      border-color: #dc3545;
    }
    .form-control {
      border: none;
      background-color: #e7e5df;
    }
    .form-password {
      border-radius: 0px;
    }
    .inputbox::placeholder {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      display: flex;
      align-items: center;
      color: #71757b;
    }

    .forgot-password {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      display: flex;
      justify-content: flex-end;
      color: #737171;
      cursor: pointer;
    }

    .already-account {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      justify-content: center;
      color: #737171;
    }

    .register-btn {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #479fd6;
      margin-left: 8px;
      cursor: pointer;
    }
  }

  .metamask-logo {
    width: 40px;
  }
}
