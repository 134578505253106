.forgot-password-area {
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;

  .forgot-container {
    background-color: #F5F5F5;
    border-radius: 20px;
    border: 9px solid #DBEBDD;
    padding: 26px;
    display: flex;
    flex-direction: column;
    justify-self: flex-start;

    .title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      align-self: center;
      font-size: max(min(1vw, 25px), 17px);
      display: flex;
      align-items: center;
      text-transform: uppercase;
      padding: 5px;
      color: #71757B;
      margin-top: 10px;
      border: none;
    }

    .form-container {
      margin-top: 30px;
    }

    .input-group-text {
      background-color: #e7e5df;
      border: none;
      color: #71757b;
      border-radius: 4.2px;
      height: 45px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
    .input-group {
      border: 1px solid rgba(188, 188, 188, 1);
      border-radius: 0.375rem;
    }
    .input-group.is-invalid {
      border-color: #dc3545;
    }
    .form-control {
      border: none;
      background-color: #e7e5df;
    }
    .form-password {
      border-radius: 0px;
    }

    .form-control:focus {
      box-shadow: none;
    }

    .inputbox {
      padding: 15px;
      background-color: #E7E5DF;
      color: #71757B;
      height: 0px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      border: none;
    }

    .otp-inputbox {
      background: rgba(226, 226, 225, 0.5);
      color: #71757B;
      border: 1px solid rgba(188, 188, 188, 1);
      box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      height: 40px;
      width: 40px;
      text-align: center;
    }

    .otp-error {
      border-color: #dc3545;
    }

    .otp-error-msg {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 0.875em;
      color: #dc3545;
    }

    .email-button-parent {
      background: linear-gradient(180deg, #0469FF 0%, #5FAFE0 0.01%, #479FD6 41.67%, #226A99 100%);
      padding: 2px;
      border-radius: max(min(2vw, 7px), 7px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .send-email-btn {
      width: auto;
      border-radius: max(min(2vw, 7px), 7px);
      margin: 0;
      order: 0;
      align-self: stretch;
      flex-grow: 1;
      font-family: 'Bookman Old Style';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.55);
      border: none;
      background: linear-gradient(90deg, #0d6efd 0%, #5FAFE0 0.01%, #479FD6 41.67%, #479FD6 100%);
    }

    .update-password-container {

      .update-password {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 30px;
        text-align: center;
        color: #71757B;
        font-size: max(min(1vw, 13px), 26px);
      }

      hr {
        border-bottom: 1px solid #71757B;
      }

      .registered-email {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #71757B;
        font-size: max(min(1vw, 13px), 24px);
      }
    }

    .form-control.is-invalid {
      border-color: #dc3545;
    }

    .inputbox::placeholder {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #71757b;
    }

    .otpContainer {
      display: flex;
      justify-content: space-between;
    }

    .lockicon {
      align-items: center;
      background-color: #E7E5DF;
      border-radius: 6px;
      border: 1px solid #BCBCBC;
      height: 47px;
    }

    .Pass_login {
      cursor: pointer;
    }

    .inputboxPassword {
      padding: 15px;
      background-color: #E7E5DF;
      color: #71757B;
      height: 0px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      border: none;
    }

    .rpasword-button-parent {
      background: linear-gradient(180deg, #0469FF 0%, #5FAFE0 0.01%, #479FD6 41.67%, #226A99 100%);
      padding: 2px;
      border-radius: max(min(2vw, 7px), 7px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .rpasword-button-child {
      width: auto;
      border-radius: max(min(2vw, 7px), 7px);
      margin: 0;
      order: 0;
      align-self: stretch;
      flex-grow: 1;
      font-family: 'Bookman Old Style';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.55);
      border: none;
      background: linear-gradient(90deg, #0d6efd 0%, #5FAFE0 0.01%, #479FD6 41.67%, #479FD6 100%);
    }

    .otpInput {
      width: 3rem;
      height: 3rem;
      margin: 0 1rem;
      font-size: 2rem;
      text-align: center;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.3);
    }

    /*  Add breakpoint for iPhone */
    @media only screen and (max-width: 375px) {
      .otpInput {
        width: 1.5rem !important;
        height: 1.5rem;
        font-size: 1rem;
        padding: 8px;
      }
    }

    .resend-otp {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-align: end;
      cursor: pointer;
      color: #737171;
    }

    .back-login {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      justify-content: center;
      color: #71757b;

      .login-back-btn {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #0469ff;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
}