.chessboard-container {
  .grey-square {
    background-image: linear-gradient(#ffce64, #ffce64) !important;
  }

  .grey-square-drag {
    background-image: linear-gradient(#fff697, #fff697) !important;
  }

  .grey-highlight-square {
    background-image: linear-gradient(#8a9355, #8a9355) !important;
  }

  .grey-highlight-square-target {
    background-image: linear-gradient(#495b43, #495b43) !important;
  }

  .grey-square-dot {
    justify-content: center;
    align-self: center;
    width: max(min(2vw, 12px), 5px);
    height: max(min(2vw, 12px), 5px);
    border-radius: 50%;
    background-color: #fff697 !important;
  }

  .grey-square-capture {
    position: absolute;
    justify-content: center;
    align-self: center;
    height: inherit;
    width: inherit;
    border-radius: 50%;
    background-color: #f3622d8e !important;
  }

  .piece-img {
    position: absolute;
    justify-content: center;
    align-self: center;
    height: inherit;
    width: inherit;
  }
}
