.user-detail-container {
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 2rem 1rem 2rem;
    color: #4c4d4f;
    border-bottom: 1px solid #d9d8d8;
    .edit-btn {
      font-style: normal;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 33px;
      margin-left: 10px;
    }
    .cancel-btn {
      font-style: normal;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 33px;
      margin-left: 10px;
      background-color: #ffffff;
      color: #dc3545;
      border-color: #dc3545;
    }
  }
  .main-detail-container {
    padding: 2rem 2rem 1rem 2rem;
    .sub-title {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.2rem;

      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #4c4d4f;
      border-bottom: 1px solid #4c4d4f;
    }
    .heading {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      display: flex;
      align-items: center;
      color: #4c4d4f;
    }
    .vertical-divider {
      border-left: 1px solid #000;
    }
    .detail {
      font-style: normal;
      font-size: 14px;
      line-height: 25px;
      display: flex;
      align-items: center;
      color: #4c4d4f;
    }
    .inputbox {
      font-size: 14px;
      height: 25px;
      color: #4c4d4f;
    }
    .avatar-container {
      width: 10%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;

      .image-container {
        max-width: 100%;
        justify-content: center;
        display: flex;

        .image {
          height: inherit;
          max-width: inherit;
        }
      }
    }
    .destroy-btn {
      font-weight: 500;
      font-size: 14px;
      color: #dc3545;
      border-color: #dc3545;
      background-color: #ffffff;
    }
    .table-cell {
      vertical-align: middle;
      text-align: center;
    }
  }
}
