.puzzle-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1rem;
  width: 100%;
  background: transparent;
  z-index: 1;

  .logo{
    height: 40px;
  }

  .card-img {
    width: max(min(5vw, 70px), 45px);
    height: max(min(5vw, 70px), 45px);
  }

  .admin-img {
    width: clamp(40px, 4vw, 60px);
    height: clamp(40px, 4vw, 60px);
  }

  .xp-container {
    background: #f8f5ee;
    border: 1px solid #e0daca;
    box-shadow: 0px 1px 0px #e0daca, inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: max(min(6vw, 100px), 50px);
    display: flex;
  }

  .star-container {
    height: max(min(3vw, 50px), 25px);
    width: max(min(3vw, 50px), 25px);
    margin-left: (calc(max(min(3vw, 50px), 25px) / -2));
    background-image: url('../../../assets/icons/Star.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2;
  }

  .level-text {
    font-family: 'Bookman Old Style';
    font-style: normal;
    font-weight: 600;
    font-size: max(min(1vw, 18px), 8px);
    line-height: 33px;
    text-align: center;
    color: #ffffff;
  }

  .progress-bar {
    max-width: max(min(6vw, 100px), 50px);
    height: inherit;
    margin-left: (calc(max(min(3vw, 50px), 25px) / -2));
    background: linear-gradient(180deg, #e3f2ff 5.59%, #0f69bd 42.83%, #175d9e 75.42%);
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    z-index: 1;
  }

  .user-detail {
    background: #f8f5ee;
    border: 1px solid #e0daca;
    box-shadow: 0px 1px 0px #e0daca, inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding-right: max(min(1vw, 15px), 10px);
  }

  .span-plus {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: max(min(2vw, 24px), 16px);
    text-align: center;
    color: #737174;
  }

  .eye-icon {
    height: max(min(3vw, 50px), 25px);
    width: max(min(3vw, 50px), 25px);
    margin-left: (calc(max(min(3vw, 50px), 25px) / -2));
  }
}