.button-parent_orange {
    background: linear-gradient(90deg, #FFC230 0%, #E19A14 100%);
    border-radius: max(min(2vw, 7px), 7px);

    .button_orange {
        font-family: 'Bookman Old Style';
        font-size: max(min(2vw, 11px), 11px) !important;
        color: #FFFFFF;
        text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.55);
        border: none !important;
        background: linear-gradient(180deg, #FFC230 0%, #E19A14 100%);
    }

}

.button-parent_red {
    background: linear-gradient(90deg, #FBAA7C 0%, #DD5300 100%);
    border-radius: max(min(2vw, 7px), 7px);

    .button_red {
        font-family: 'Bookman Old Style';
        font-size: max(min(2vw, 11px), 11px) !important;
        color: #FFFFFF;
        text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.55);
        border: none !important;
        background: linear-gradient(180deg, #FBAA7C 0%, #DD5300 100%);
    }
}