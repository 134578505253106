.game-board-homescreen {
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: fill;
    z-index: 0;
    height: 100%;
    width: 100%;
  }
}
