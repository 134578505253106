.admin-layout-container {
  background-color: #eeeeee;
  display: flex;

  .admin-page-container {
    align-items: center;
    display: flex;
    min-height: 100vh;

    .admin-container {
      display: flex;
      justify-content: center;
      min-height: 100vh;
      width: 100%;

      .sidebar-container {
        background-color: #ffffff;
        margin-top: 5vh;
        margin-bottom: 5vh;
        border-radius: 10px;
        padding: 1rem;
        height: 90vh;
      }

      .detail-container {
        padding: 0rem;
        background-color: #ffffff;
        margin-top: 5vh;
        margin-bottom: 5vh;
        border-radius: 10px;
        min-height: 90vh;
      }
    }
  }
}
