.ads-modal {
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  position: absolute;
  bottom: 0;
  left: -5vw;
  right: -5vw;

  .ads-modal__container {
    display: flex;
    flex-direction: column;
  }
}
