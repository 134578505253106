.chess-board {
  overflow: hidden;
  position: relative;
  margin: auto;
  display: flex;
  cursor: pointer !important;
  .board-background {
    img {
      width: 100%;
    }
  }
}