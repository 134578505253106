.whitelist-page-container {
  background-color: rgb(237 229 254);
  height: '100%';
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 85vh;

  .whitelist-page__text {
    color: rgb(114	109	125);
    font-weight: 900;
    font-size: 24px;
  }

  .whitelist-page__title {
    margin-bottom: 20px;
    color: rgb(114	109	125);
    font-weight: 900;
    font-size: 32px;
  }

  .whitelist-page__join-button {
    margin-top: 20px;
    background-color: rgb(0	211	251);
    color: white;
    font-weight: 900;
    border-radius: 16px;
    padding: 20px;
    border: 3px solid rgb(31	81	149);
    width: 300px;
  }

  .whitelist-page__connect-button {
    margin-top: 20px;
    background-color: rgb(117	76	249	);
    color: white;
    font-weight: 900;
    border-radius: 16px;
    padding: 20px;
    border: 3px solid rgb(63	47	145);
    width: 300px;
  }

  .whitelist-page__loading-text {
    margin-top: 20px;
    color: rgb(114	109	125);
    font-weight: 900;
    font-size: 24px;
  }
}

