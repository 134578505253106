.logout-component {
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;

  .white-text {
    color: white;
  }
  .dark-text {
    color: #4c4d4f;
  }

  .logout-light__container {
    background-color: #ffffff;
    border: 1.9px solid #ffffff;
  }

  .logout-dark__container {
    background-color: #161616;
    border: 1.9px solid #c1c1c1;
  }

  .logout-component__container {
    border-radius: 10px;
    padding: 26px;
    display: flex;
    flex-direction: column;
    justify-self: flex-start;

    .logout-btn {
      box-sizing: border-box;
      font-family: 'Open Sans';
      font-style: normal;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      color: #fff;
      display: flex;
      justify-content: center;
      padding: 9px 25px;
      gap: 12px;
      border: 3px solid transparent;
      background: #0469ff;
      box-shadow: 0px 2px 12px rgba(73, 119, 17, 0.2);
      border-radius: 10px;
      width: 100%;
    }

    .cancel-btn {
      box-sizing: border-box;
      font-family: 'Open Sans';
      font-style: normal;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      color: #fff;
      display: flex;
      justify-content: center;
      padding: 9px 25px;
      gap: 12px;
      border: 3px solid transparent;
      background: #62615f;
      box-shadow: 0px 2px 12px rgba(73, 119, 17, 0.2);
      border-radius: 10px;
      width: 100%;
    }
  }
}
