.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-item {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #c1c0bc;
  padding: 0px 10px;
}
.sidebar-item-selected {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #0469ff;
  padding: 0px 10px;
}

.menu-admin-link {
  padding-left: 0px !important;
  padding-right: 0px !important;
  align-items: center;
  display: flex;
}

.back-site-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}
.logout-admin-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-top: 1rem;
  color: #0469ff;
  background-color: #ffffff;
}