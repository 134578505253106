.main-container {
  position: relative;
  margin: 5%;
  .title-main-container {
    padding: 0px 10%;
    position: relative;
    z-index: 3;

    .title-background {
      background-image: url('../../../../assets/img/title.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      z-index: 3;
      padding: 3% 7% 11% 7%;
    }
    .title-text {
      font-family: 'Bookman Old Style';
      font-style: normal;
      font-weight: 600;
      font-size: clamp(14px, 1.6vw, 28px);
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
    }
  }

  .popup-details {
    position: relative;
    margin-top: -10%;

    .close-container {
      position: absolute;
      top: -30px;
      right: -30px;
      z-index: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .close-background {
        background-color: transparent;
        border: none;
        height: 70px;
        width: 70px;
        .close-img {
          height: 100%;
          width: 100%;
        }
      }
    }

    .detail-main-container {
      position: relative;
      background: #f8d5a5;
      border: 3px solid #9c5c2c;
      box-shadow: 0px 0px 1px 4px #d09b68, 0px 8px 3px #924a18, 0px 6px 1px 5px #d09b68;
      border-radius: 10px;
      z-index: 2;
      .detail-title-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5%;
        margin-bottom: 5%;

        .detail-title-text {
          flex-direction: column;
          font-family: 'Bookman Old Style';
          font-style: normal;
          font-weight: 600;
          font-size: clamp(12px, 1.4vw, 24px);
          display: flex;
          align-items: center;
          text-align: center;

          color: #838183;
          .bottom-underline {
            height: 2px;
            width: 100%;
            background: linear-gradient(89.99deg, #747474 12.5%, #ece8dd 105.76%);
          }
        }
      }

      .detail-container {
        padding: 2% 5%;
        display: flex;
        justify-content: space-between;
        .nft-container {
          width: 40%;
          .nft-card-container {
            background: #f8f5ee;
            border: 1px solid #e0daca;
            box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5%;
            padding: 2%;
            height: 100%;
            position: relative;
            .nft-image-container {
              border-radius: 5%;
              height: 100%;
              .nft-image {
                height: 100%;
                width: 100%;
              }
            }
            .piece-icon-container {
              position: absolute;
              top: 0px;
              left: 0px;
              .piece-icon {
                height: 20%;
                width: 20%;
              }
            }
          }
        }

        .card-detail-container {
          width: 50%;
          .piece-text-container {
            display: flex;
            align-items: center;

            .piece-title {
              font-family: 'Bookman Old Style';
              font-style: normal;
              font-weight: 700;
              font-size: clamp(12px, 1.2vw, 18px);
              color: #747275;
            }
            .piece-text {
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 500;
              font-size: clamp(12px, 1.2vw, 18px);
              margin-top: 1.4%;
              margin-left: 2%;
              color: #747275;
            }
          }
        }
      }
      .button-main-container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .button-container {
          .upgrade-btn-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .upgrade-text {
              font-family: 'Bookman Old Style';
              font-style: normal;
              font-weight: 600;
              font-size: clamp(12px, 1.2vw, 18px);
              display: flex;
              align-items: center;
              text-align: center;
              color: #747474;
            }
            .upgrade-btn {
              display: flex;
              width: 100%;
              background: radial-gradient(6.18% 10.61% at 9.41% 19.7%, #ffffff 0%, #9fc74e 100%)
                  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
                #9fc74e;
              border: 1px solid #d6c8ad;
              box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25),
                inset 0px -1px 4px 3px rgba(0, 0, 0, 0.28);
              border-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 1%;
              height: 40px;
              .coin {
                height: 20px;
              }
              .amount-text {
                font-family: 'Bookman Old Style';
                font-style: normal;
                font-weight: 600;
                font-size: clamp(12px, 1.2vw, 18px);
                display: flex;
                align-items: center;
                text-align: center;
                color: #ffffff;
                text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
              }
            }
          }
        }

        .use-btn-container {
          background: radial-gradient(6.5% 11.52% at 7.69% 18.18%, #ffffff 0%, #f0af26 100%),
            linear-gradient(180deg, #ffc230 0%, #e19a14 100%);
        }
        .remove-btn-container {
          background: radial-gradient(7.6% 11.36% at 9.13% 19.32%, #ffffff 0%, #f78529 100%),
            linear-gradient(180deg, #ff9330 0%, #e15e14 100%);
        }

        .btn-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 40px;

          border: 1px solid #d6c8ad;
          box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25),
            inset 0px -2px 6px 6px rgba(138, 34, 1, 0.28);
          border-radius: 10px;

          .remove-text {
            font-family: 'Bookman Old Style';
            font-style: normal;
            font-weight: 600;
            font-size: clamp(12px, 1.2vw, 18px);
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: uppercase;

            color: #ffffff;

            text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
}
