.collection-page {
  background-color: '#0e0e0e';
  .filter-area {
    .deck-title {
      font-family: 'Bookman Old Style';
      font-style: normal;
      font-weight: 600;
      font-size: clamp(15px, 1.5vw, 28px);
      line-height: 33px;
      text-align: center;

      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .card-container-out {
      background: linear-gradient(
          360deg,
          rgba(205, 184, 128, 0.2) 16.84%,
          rgba(134, 119, 81, 0.2) 116.84%
        ),
        linear-gradient(180deg, #f2e5c1 0%, rgba(255, 232, 171, 0) 5.79%);
      padding: 2%;
      border-radius: 8%;
      width: clamp(50px, 10vw, 250px);
      height: clamp(50px, 10vw, 250px);
      .card-container {
        background: #f8f5ee;
        border-radius: 8%;
        justify-content: center;
        height: 100%;
        width: 100%;
        align-items: center;
        display: flex;
        position: relative;
        padding: 5%;
        .piece-image {
          height: 30%;
        }
      }
    }
  }
  .right-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .filter-container {
      background: #e0daca;
      border-radius: clamp(8px, 1.5vw, 15px) clamp(8px, 1.5vw, 15px) 0px 0px;
      padding: 1.5% 2% 3.5% 2%;

      .filter-details {
        display: flex;
        justify-content: space-between;
        background: #f8f5ee;
        border: 1px solid #e0daca;
        border-radius: 2px;
        padding: 1%;
        width: 100%;
        margin: auto;
      }
      .filter-orange-button {
        background: radial-gradient(6.5% 11.52% at 7.69% 18.18%, #ffffff 0%, #f0af26 100%)
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
          linear-gradient(180deg, #ffc230 0%, #e19a14 100%);
      }
      .filter-blue-button {
        background: radial-gradient(
              6.92% 12.5% at 8.46% 20.45%,
              #ffffff 0%,
              #54a5d8 99.99%,
              #54a5d8 100%
            )
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
          linear-gradient(180deg, #0469ff 0%, #5fafe0 0.01%, #479fd6 41.67%, #479fd6 100%);
      }
      .filter-button {
        border: 2px solid #d6c8ad;
        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25),
          inset 0px -2px 6px 6px rgba(138, 34, 1, 0.28);
        border-radius: 10px;

        font-family: 'Bookman Old Style';
        font-style: normal;
        font-weight: 600;
        font-size: clamp(8px, 1vw, 24px);
        /* identical to box height */

        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        text-transform: uppercase !important;

        color: #ffffff;

        text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(0, 0, 0, 0.25);
      }
      .detail-container {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        .title-text {
          font-family: 'Bookman Old Style';
          font-style: normal;
          font-weight: 600;
          font-size: clamp(12px, 1.2vw, 28px);
          color: #737174;
        }
        .filter-count-text {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: clamp(8px, 1vw, 24px);
          color: #71757b;
        }
      }
    }
    .nft-container {
      margin-top: -2%;
      background: #f8f5ee;
      box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
      border-radius: clamp(8px, 1.5vw, 15px);
      height: 70vh;
      padding: 2%;
      overflow-y: auto;
      overflow-x: hidden;

      .nft-card-container {
        background: #f8f5ee;
        border: 1px solid #e0daca;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5%;
        padding: 3%;
        height: 100%;
        position: relative;
      }
    }
  }
  .nft-image-container {
    border-radius: 5%;
    height: 100%;
    .nft-image {
      border-radius: 5%;
      height: 100%;
      width: 100%;
    }
  }
  .piece-icon-container {
    position: absolute;
    top: 1%;
    left: 1%;
    height: 20%;
    width: 20%;
    display: flex;
    .piece-icon {
      height: 100%;
      width: 100%;
    }
  }
}
.modal-content {
  background-color: transparent !important;
  border: none !important;
}
