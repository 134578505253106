.lose-modal {
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;

  .you-lose {
    height: 70px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background: linear-gradient(180deg, #FA693B 0%, #EA6921 100%);
    box-shadow: 0px 3px 4px 1px rgba(0, 0, 0, 0.25);
    padding-top: 13px;
    margin-top: -1px;
    margin-right: -1px;
  }

  .white-text {
    color: white;
    font-family: 'Bookman Old Style';
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    align-items: center;
    text-transform: capitalize;
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25), 0px 3px 0px rgba(0, 0, 0, 0.25);
    font-size: max(min(4vw, 40px), 20px);
  }

  .eyes-lose {
    background: rgba(236, 232, 221, 0.5);
    box-shadow: 0px 1px 0px #E0DACA, inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 5px 55px 0px 15px;
  }

  .span-minus {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    font-size: max(min(4vw, 28px), 22px);
    text-align: right;
    color: #737174;
  }

  .win-lose {
    background: rgba(236, 232, 221, 0.5);
    box-shadow: 0px 1px 0px #E0DACA, inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 5px 75px 0px 15px;
  }

  .span-plus {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    font-size: max(min(4vw, 28px), 22px);
    text-align: right;
    color: #737174;
  }

  .lose-modal-container {
    background-color: #F5F5F5;
    border-radius: 20px;
    border: 9px solid #DBEBDD;
    padding: 0px;
    flex-direction: column;
    justify-self: flex-start;

    .button-parent {
      background: linear-gradient(90deg, #0469FF 0%, #5FAFE0 0%, #479FD6 100%);
      padding: 2px;
      border-radius: max(min(2vw, 10px), 10px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .home-btn {
      width: auto;
      border-radius: max(min(2vw, 10px), 10px);
      margin: 0 !important;
      order: 0;
      align-self: stretch;
      flex-grow: 1;
      font-family: 'Bookman Old Style';
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.55);
      border: none;
      background: linear-gradient(180deg, #0469FF 0%, #5FAFE0 0.01%, #479FD6 41.67%, #479FD6 100%);
      padding: 16px 40px;
      font-size: max(min(4vw, 28px), 22px);
    }

    .button-play {
      background: linear-gradient(90deg, #FFC230 0%, #E19A14 100%);
      padding: 2px;
      border-radius: max(min(2vw, 10px), 10px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .play-btn {
      width: auto;
      border-radius: max(min(2vw, 10px), 10px);
      margin: 0 !important;
      order: 0;
      align-self: stretch;
      flex-grow: 1;
      font-family: 'Bookman Old Style';
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.55);
      border: none;
      background: linear-gradient(180deg, #FFC230 0%, #E19A14 100%);
      padding: 16px 10px;
      font-size: max(min(4vw, 28px), 22px);
    }
  }
}