.marketplace-container {
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: center;
    padding: 2rem 2rem 1rem 2rem;
    color: #4c4d4f;
    border-bottom: 1px solid #d9d8d8;
  }
  .main-detail-container {
    padding: 2rem 2rem 1rem 2rem;

    .avatar-container {
      width: 10%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;

      .image-container {
        max-width: 100%;
        justify-content: center;
        display: flex;

        .image {
          height: inherit;
          max-width: inherit;
        }
      }
    }
    .table-cell {
      vertical-align: middle;
      text-align: center;
    }
  }
  .pagination {
    justify-content: end;
  }
}
