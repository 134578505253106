@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap');

@font-face {
  font-family: 'BookmanOldStyle';
  src: local('Bookman Old Style'),
    url('./assets/fonts/Bookman-Old-Style-Bold.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: 'Fira Sans';
  font-weight: 900;
  src: local('Fira Sans'),
    url('./assets/fonts/FiraSans-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-weight: 400;
  src: local('Fira Sans'),
    url('./assets/fonts/FiraSans-Regular.ttf') format('truetype');
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #F8F5EE;
  color: #b6b5b4;
  font-family: 'Open Sans';
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

body {
  overflow-y: auto;
}

/* spinner */
.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #fff;
  border-color: #2ecc71 transparent #2ecc71 transparent;
  animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  100% {
    transform: rotate(1800deg);
  }
}

/* spinner------------------------ */

/* custom scollbar */
/* width */
::-webkit-scrollbar {
  height: 4px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}