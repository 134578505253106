.exit-modal {
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;

    .you-exit {
        height: 70px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background: linear-gradient(180deg, #5DAEE0 0%, #48A0D7 100%);
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
        padding-top: 13px;
        margin-top: -1px;
        margin-right: -1px;
    }

    .white-text {
        font-family: 'Bookman Old Style';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 42px;
        align-items: center;
        text-transform: capitalize;
        color: #FFFFFF;
        text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25), 0px 3px 0px rgba(0, 0, 0, 0.25);
    }

    .exit-p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 34px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #737171;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .exit-modal-container {
        background-color: #F5F5F5;
        border-radius: 20px;
        border: 9px solid #DBEBDD;
        padding: 0px;
        flex-direction: column;
        justify-self: flex-start;

        .button-yes {
            background: linear-gradient(180deg, #0469FF 0%, #5FAFE0 0.01%);
            padding: 2px;
            border-radius: max(min(2vw, 10px), 10px);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .yes-btn {
            width: auto;
            border-radius: max(min(2vw, 10px), 10px);
            margin: 0;
            order: 0;
            align-self: stretch;
            flex-grow: 1;
            font-family: 'Bookman Old Style';
            font-style: normal;
            font-weight: 600;
            font-size: 23px;
            line-height: 27px;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: #ffffff;
            text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4), 0px 2px 0px rgba(0, 0, 0, 0.35);
            border: none;
            background: linear-gradient(90deg, #0d6efd 0%, #5FAFE0 0.01%, #479FD6 41.67%, #479FD6 100%);
            padding: 12px 40px;
        }

        .button-no {
            background: linear-gradient(90deg, #FF9330 0%, #E15E14 100%);
            padding: 2px;
            border-radius: max(min(2vw, 10px), 10px);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .no-btn {
            width: auto;
            border-radius: max(min(2vw, 10px), 10px);
            margin: 0;
            order: 0;
            align-self: stretch;
            flex-grow: 1;
            font-family: 'Bookman Old Style';
            font-style: normal;
            font-weight: 600;
            font-size: 23px;
            line-height: 27px;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: #ffffff;
            text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25), 0px 2px 0px rgba(0, 0, 0, 0.25);
            border: none;
            background: linear-gradient(180deg, #FF9330 0%, #E15E14 100%);
            padding: 12px 40px;
        }
    }
}