.game-board-pvai {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E0DACA;
  .row {
    flex-wrap: nowrap;
  }

  .board-area {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .chessboard {
      margin: auto;
      background-image: url('../../assets/img/gameboard.png');
      background-repeat: no-repeat;
      background-size: contain;
    }

    .board-area-rank {
      color: #737174;
      margin-bottom: 0rem;
      font-size: max(min(2vw, 15px), 10px);
    }

    .board-area-name {
      color: #737174;
      font-weight: 600;
      font-size: max(min(2vw, 23px), 14px);
    }

    .time-text {
      color: #737174;
      font-size: max(min(2vw, 32px), 19px);
    }

    .profile-img {
      width: max(min(5vw, 100px), 50px);
    }

    .pieces-container {
      height: 20px;

      .pieces-text {
        font-size: max(min(2vw, 18px), 12px);
      }
    }

  }

  .marginLeftBoardArea {
    margin-left: -150px;
  }

  .handle-area {
    background: #F8F5EE;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px 15px 0px 0px;
    padding-top: 135px;

    .handle-container {
      background-color: #ECE8DD;
      border-radius: 15px;
    }

  }

  .handle-container-main {
    opacity: 0.9;
    height: 10vh;
    background-color: #ECE8DD;
    border-radius: 0px 0px 15px 15px;
  }

  .treeMargin {
    margin-left: -120px;
  }

}

.modal-container {
  align-items: inherit !important;
}

.result-modal-container {
  background-color: transparent !important;
  justify-content: center;
  border: none !important;
}