.chess-target {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.5%;
  height: 12.5%;
  .normal{
    width: 15%;
    height: 15%;
    border-radius: 50%;
    background-color: #00000070;
  }
  .capture{
    width: 100%;
    height: 100%;
    background-color: #FF685F99;
  }
}