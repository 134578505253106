.api-container {
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4c4d4f;
    border-bottom: 1px solid #d9d8d8;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    border-bottom: 0px;
  }
  .duration-container {
    display: flex;
    justify-content: space-between;
  }
  .month-container {
    margin-right: 1.5rem;
  }
  .main-detail-container {
    padding: 2rem 2rem 1rem 2rem;
    .table-cell {
      vertical-align: middle;
      text-align: center;
    }
  }
  .chart-container {
    width: 100%;
    height: 600px;
  }
}
