.buy-nft-modal {
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;

  .white-text {
    color: white;
  }

  .buy-nft-modal__container {
    background-color: #161616;
    border-radius: 10px;
    border: 1.9px solid #c1c1c1;
    padding: 26px;
    display: flex;
    flex-direction: column;
    justify-self: flex-start;

    .buy-nft-modal__container__btn {
      box-sizing: border-box;
      font-family: 'Open Sans';
      font-style: normal;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      color: #fff;
      display: flex;
      justify-content: center;
      padding: 9px 25px;
      gap: 12px;
      border: 3px solid transparent;
      background: #0469ff;
      box-shadow: 0px 2px 12px rgba(73, 119, 17, 0.2);
      border-radius: 10px;
      width: 100%;
    }
  }
}
