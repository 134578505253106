.title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
  padding: 2rem 2rem 1rem 2rem;
  color: #4c4d4f;
  border-bottom: 1px solid #d9d8d8;
}

.button-container {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}