.puzzle-page-container {
  align-items: center;
  justify-content: center;
  background:
    linear-gradient(#6D22CD, #8561D1, #9745FF, #261A49),
    radial-gradient(#FFFFFF, #616161, #831495);

  mix-blend-mode: multiply;
  height: 100vh;

  .side-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #EFE8FF;
    border-radius: 10px;
    min-height: 100%;
    height: auto !important;
    height: 100%;

    .profile-detail {
      color: #000;
      font-family: Fira Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .result-container {
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      text-align: center;
      .result-text {
        color: #FFF;
        align-self: stretch;
        font-family: Fira Sans;
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }
    }

    .default {
      background-color: #0CA3F8;
    }

    .error {
      background-color: #FF685F;
    }

    .success {
      background-color: green;
    }

    .side-container-title {
      color: #2D2D2D;
      font-family: Fira Sans;
      font-size: 22px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }

    .divider {
      width: 100%;
      border-bottom: 1px #C3B6E8 solid;
    }

    .side-container-description {
      color: #000;
      text-align: center;
      font-family: Fira Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .social-icon{
      max-width: 100%;
      max-height: 100%;
    }
    .social-title{
      color: #000;
      text-align: start;
      font-family: Fira Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
  }
}