.quick-match-area {
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;

  .register-container {
    background-color: #F5F5F5;
    border-radius: 20px;
    border: 9px solid #DBEBDD;
    padding: 26px;
    display: flex;
    flex-direction: column;
    justify-self: flex-start;

    .title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 30px;
      align-self: center;
      font-size: max(min(2vw, 30px), 16px);
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #71757B;
      margin-top: 10px;
      border-bottom: 2px solid #71757B;
    }

    .title-tagline {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-self: center;
      margin-top: 15px;
      color: #71757b;
    }

    .form-container {
      margin-top: 30px;
    }

    .button-parent {
      background: linear-gradient(180deg, #0469FF 0%, #5FAFE0 0.01%, #479FD6 41.67%, #226A99 100%);
      padding: 2px;
      border-radius: max(min(2vw, 7px), 7px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .register-btn {
      width: auto;
      border-radius: max(min(2vw, 7px), 7px);
      margin: 0;
      order: 0;
      align-self: stretch;
      flex-grow: 1;
      font-family: 'Bookman Old Style';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.55);
      border: none;
      background: linear-gradient(90deg, #0d6efd 0%, #5FAFE0 0.01%, #479FD6 41.67%, #479FD6 100%);
    }

    .form-control:focus {
      box-shadow: none;
    }

    .input-label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #b1b1b1;
    }

    
    .input-group-text{
      background-color: #E7E5DF;
      border: none;
      color: #71757B;
      border-radius: 4.2px;
      height: 45px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
    .input-group {
      border: 1px solid rgba(188, 188, 188, 1);
      border-radius: 0.375rem;
    }
    .input-group.is-invalid {
      border-color: #dc3545;
    }
    .form-control{
      border: none;
      background-color: #E7E5DF;
    }
    .form-password{
      border-radius: 0px;
    }

    .inputbox::placeholder {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #71757b;
    }

    .already-account {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      display: flex;
      justify-content: center;
      color: #747474;

      .login-btn-txt {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #747474;
        cursor: pointer;
      }
    }
  }
}