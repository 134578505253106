.opening-db-container {
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 2rem 1rem 2rem;
    color: #4c4d4f;
    border-bottom: 1px solid #d9d8d8;
  }
  .main-detail-container {
    padding: 0rem 1rem;

    .detail-container{
      height: '100%' ;
    }
    .pointer-cursor {
      cursor: pointer;
    }
    .edit-btn-container{
      display: inline-flex;
      justify-content: center;
      
    }

    .edit-btn {
      font-weight: 500;
      font-size: 14px;
      color: #0469ff;
      background-color: #ffffff;
      margin: 0px 0.2rem;
      justify-content: center;
      text-align: center;
      min-width: 70px;

      &:hover {
        color: #ffffff;
        background-color: #0469ff;
      }
    }
    .destroy-btn {
      font-weight: 500;
      font-size: 14px;
      color: #dc3545;
      border-color: #dc3545;
      background-color: #ffffff;
    }
    .table-cell {
      vertical-align: middle;
      text-align: center;
    }
    .table-data {
      text-align: left;
      max-width: 240px;
    }

  }
  .pagination {
    justify-content: end;
  }
  .search-container {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 2rem;
    
  }
  .search-input {
    width: 30%;
    border-radius: 5px;
    border: 1px solid #d9d8d8;
  }
  .search-btn {
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    background-color: #0469ff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }
  .no-data{
    padding: 1rem 2rem;
    text-align: center;
  }
  .pagination-container{
    padding: 1rem 2rem;
  }
  .edit-input{
    width: 100%;
  }
}
