.user-detail-container {
  z-index: 1;

  .anonymous-img {
    border: 1px solid #e0daca;
    border-radius: 10px;
    display: flex;
    align-items: center;
    background: #f8f5ee;
    z-index: 1;

    .user-img {
      border: 1px solid #e0daca;
      padding: 5px;
      border-radius: 5px;
      margin-left: 5px;
      width: 100px;
      height: 100px;
      font-size: max(min(4vw, 44px), 20px);
    }

    .anonymous-paragraph {
      font-family: 'Bookman Old Style';
      font-style: normal;
      font-weight: 600;
      font-size: 31px;
      line-height: 36px;
      color: #737174;
      padding-left: 5px;
      font-size: max(min(4vw, 26px), 20px);
    }
  }

  .button-parent {
    padding: 2px;
    border-radius: max(min(2vw, 7px), 7px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-data {
    border-radius: max(min(2vw, 7px), 7px);
    margin: 0 !important;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    font-family: 'Bookman Old Style';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    font-size: max(min(4vw, 33px), 20px);
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(0, 0, 0, 0.25);
    border: none;
  }

  .play-button-parent {
    background: linear-gradient(180deg, #ffc230 0%, #e19a14 100%);
  }

  .play-button {
    background: linear-gradient(90deg, #ffc230 0%, #e19a14 100%);
  }

  .puzzle-button-parent {
    background: linear-gradient(180deg, #0469ff 0%, #5fafe0 0.01%, #479fd6 41.67%, #0d6efd 100%);
  }

  .puzzle-button {
    background: linear-gradient(90deg, #0d6efd 0%, #5fafe0 0.01%, #479fd6 41.67%, #479fd6 100%);
  }
}
