.button-container-wrapper {
  padding: 2px;
  border-radius: 10px;
  display: flex;

  .button-container-stroke {
    width: 100%;
    padding: 1px;
    border-radius: 10px;
    display: flex;

    .button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 10px;
      border: none;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0.50);
      text-align: center;
      /* Font-Shadow */
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.50);
      /* Label-Med */
      font-family: Fira Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      font-variant: small-caps;
    }
    .button-container :disabled{
      background-color: grey;
    }
  }
}

.primary {
  background: linear-gradient(#376FC7, #274D8A);

  .button-container-stroke {
    background:
      linear-gradient(to bottom right, #E5FFFD 3%, #B5EDFF 10%, #2FF3FF 43%, #0CA3F8 45%);

    .button-container {
      background-color: #29D8FE;
    }
  }

  .button-container:hover {
    background-color: #29D8FE !important;
  }
}

.secondary {
  background: linear-gradient(#5948BE, #3B2789);

  .button-container-stroke {
    background:
      linear-gradient(to bottom right, #9997FC 3%, #806BFF 10%, #9479FD 43%, #6133C0 45%);

    .button-container {
      background-color: #8054FF;
    }
  }

  .button-container:hover {
    background-color: #8054FF !important;
  }
}